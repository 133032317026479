@font-face {
  font-family: hafz;
  src: url('quran/hafz.otf');
}
body {
  background-color: #333335;
  color: white;
}

.Verses {
  font-family: hafz;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.Verses h1 {
  line-height: 1.75;
  /* letter-spacing: 1px; */
}

.Answers {
  color: black;
}

.ModalTitle {
  color: black !important;
}

.bismillah {
  font-family: hafz, cursive;
  font-size: 1.2rem;
}

.EasyButton {
  border: 1px solid rgb(92, 92, 92);
  box-shadow: 1px 1px 1px 1px rgb(72, 72, 72);
}

.modal-open[style] {
  padding-right: 0px !important;
  }

.Select {
  width: 50vw !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
