.App {
  text-align: center;
  width: 100vw !important;
  min-height:100vh !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.NavBar {
  font-size: 2em;
  font-family: 'Pacifico', cursive;
  background-color: rgb(116, 116, 116);
  padding: 0%;
}

.modal-content{
  background-color: rgb(214, 214, 214);
  color: rgb(66, 66, 66) !important;
  text-align: center;
}

.modal-body {
  font-family: 'Poppins', sans-serif;
  font-size: large;
}
.modal-header {
  font-family: 'Pacifico', cursive;
}

.rules {
  list-style: none;
  padding: 0;
}

.rules .li-not-last {
  border-bottom: solid 1.75px rgb(182, 182, 182);
  padding: 5px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
